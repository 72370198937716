import React, { useEffect, useState } from "react";
import AuthService from "../services/AuthService";
import { navigate } from "@reach/router";
import SystemService from "../services/SystemService";

export const UserContext = React.createContext();

function UserProvider({ children }) {
  const [token, setToken] = useState(null);
  const [userObject, setUserObject] = useState({});
  const [authSetting, setAuthSetting] = useState({});

  useEffect(() => {
    try {
      function getToken() {
        try {
          const local_token = localStorage.getItem("auth_token");
          if (local_token) setToken(local_token);
        } catch (error) {
          console.log(error);
        }
      }
      getToken();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        const user = await AuthService.profile();
        const response = await SystemService.getConfigAuth();
        if (response.systemConfig) {
          setAuthSetting(response.systemConfig);
        }
        if (user?.me) {
          setUserObject(user.me);
        }
      } catch (error) {
        localStorage.removeItem("auth_token", "");
        navigate("/");
      }
    }
    try {
      if (token) {
        if (token === "logged_out") {
          localStorage.setItem("auth_token", "");
          navigate("/");
          setUserObject({});
          setToken(null);
        } else {
          localStorage.setItem("auth_token", token);
          fetchUserProfile();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  const value = React.useMemo(() => {
    return {
      token,
      setToken,
      userObject,
      setUserObject,
      authSetting,
      setAuthSetting,
    };
  }, [token, setToken, userObject, setUserObject, authSetting, setAuthSetting]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserProvider;
